import * as _ from 'lodash'
import {Module} from 'vuex'

import BaseInterface from '@/models/base'
import SearchData from '@/models/search-data'

export const DefaultSearchData: SearchData = {
  routeType: 'one-way',
  maximumDestinations: 1,
  destinations: [],
  passengers: null,
  dates: null,
  departure: null,
  arrival: null,
  contiguous: true,
}

export const search: Module<any, any> = {
  namespaced: true,
  state: {
    searchData: {...DefaultSearchData},
    destinations: [],
    bases: [],
    distances: [],
  },
  getters: {
    touched: state => !_.isEqual(state.searchData, DefaultSearchData),
    destinations: state => state.destinations,
    firstDestination: state => state.destinations[0],
    getDestination: state => (destinationIndex: number) => {
      state.destinations.find((destination: BaseInterface) => destination.destinationIndex === destinationIndex)
    },
    bases: state => _.filter(state.bases),
    passengers: state => state.searchData.passengers,
    routeType: state => state.searchData.routeType,
    duration: state => state.searchData.duration,
    twinOnly: state => {
      return _.some(state.destinations, 'twinEngineRequired')
    },
  },
  actions: {
    async reset({commit}) {
      commit('RESET')
    },
    async update({commit}, searchData: SearchData) {
      commit('UPDATE_SEARCH_DATA', searchData)
    },
    async setDestination({commit}, {destination, destinationIndex}) {
      commit('SET_DESTINATION', {destination, destinationIndex})
    },
    async setDestinations({commit}, destinations) {
      commit('SET_DESTINATIONS', destinations)
    },
    async clearDestinations({commit}) {
      commit('CLEAR_DESTINATIONS')
    },
    async addBase({commit}, base) {
      commit('ADD_BASE', base)
    },
    async setBases({commit}, bases) {
      commit('SET_BASES', bases)
    },
    async clearBase({commit}, base) {
      commit('CLEAR_BASE', base)
    },
    async clearBases({commit}) {
      commit('CLEAR_BASES')
    },

    async setDistances({commit}, distances) {
      commit('SET_DISTANCES', distances)
    },
    async clearDistances({commit}) {
      commit('CLEAR_DISTANCES')
    },

    async setMaximumDestinations({commit}, maximumDestinations: number) {
      commit('SET_MAXIMUM_DESTINATIONS', maximumDestinations)
    },
  },
  mutations: {
    RESET(state) {
      state.searchData = {...DefaultSearchData}
      state.searchData.destinations = []
    },

    UPDATE_SEARCH_DATA(state, searchData: SearchData) {
      state.searchData = searchData
    },

    SET_DESTINATION(state, {destination, destinationIndex}) {
      const found = state.destinations.findIndex((destn: BaseInterface) => destn.destinationIndex === destinationIndex)
      if (found > -1) {
        state.destinations.splice(found, 1, destination)
      } else {
        state.destinations.push(destination)
      }
    },

    SET_DESTINATIONS(state, destinations) {
      state.destinations = destinations.slice(0)
    },

    CLEAR_DESTINATIONS(state) {
      state.destinations = []
    },

    SET_DISTANCES(state, distances) {
      state.distances = distances
    },
    CLEAR_DISTANCES(state) {
      state.distances = []
    },

    ADD_BASE(state, base) {
      state.bases.push(base)
    },

    SET_BASES(state, bases) {
      state.bases = bases
    },

    CLEAR_BASE(state, base) {
      _.remove(state.bases, (stateBase: any) => base.VenueId === stateBase.VenueId)
    },

    CLEAR_BASES(state) {
      state.bases = []
    },

    SET_MAXIMUM_DESTINATIONS(state, maximumDestinations: number) {
      state.searchData.maximumDestinations = DefaultSearchData.maximumDestinations = maximumDestinations
    },
  },
}
