















































































import PriceFormat from '@/components/results/price-format.vue'
import DateTimeSelector from '@/components/sidebar/date-time-selector.vue'
import Destination from '@/components/sidebar/destination.vue'
import Passengers from '@/components/sidebar/passengers.vue'

import Utilities from '@/mixins/utilities'

import BaseInterface from '@/models/base'
import SearchData from '@/models/search-data'

import {PackageAPI} from '@/models/package'
import _ from 'lodash'
import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'
import {mapState} from 'vuex'

@Component({
    components: {
      PriceFormat,
      Destination,
      Passengers,
      DateTimeSelector,
    },
    computed: {
      ...mapState('search', {
        search: 'searchData',
      }),
    },
  })
  export default class SearchForm extends mixins(Utilities) {
    search: SearchData
    maxDate: any = this.$moment().add(5, 'years').endOf('year').toDate()
    isLoading: boolean = true
    loading: string = 'packages'

    get buttonDisabled(): boolean {
      if (this.search.dates === null) {
        return true
      }

      switch (this.search.routeType) {
        case 'one-way':
        case 'return':
          return this.search.destinations.length < 2
        case 'tour':
          return this.search.destinations.length !== 1 || this.search.duration === undefined
      }

      return true
    }

    get isOneWay(): boolean {
      return this.search.routeType === 'one-way'
    }

    get isReturn(): boolean {
      return this.search.routeType === 'return'
    }

    get isTour(): boolean {
      return this.search.routeType === 'tour'
    }

    get routeType(): string {
      return this.search.routeType
    }

    set routeType(routeType: string) {
      if (this.search.routeType !== routeType) {
        if (routeType === 'tour' && this.search.destinations.length > 1) {
          this.search.destinations = [this.search.destinations[0]]
        }

        this.$store.dispatch('search/clearBases')
        this.$store.dispatch('search/setDestinations', this.search.destinations)

        this.$store.dispatch('capture/deselectResult')
      }

      this.search.routeType = routeType
      this.updateSearch()
    }

    selectDestination(destination: BaseInterface) {
      if (destination) {
        this.$store.dispatch('search/setDestination', {destination, destinationIndex: destination.destinationIndex})
      }
    }

    updateSearch(): void {
      this.updateSentryContext('search', this.search)
      this.$store.dispatch('search/update', this.search)
    }

    clickResults() {
      this.updateSearch()
      this.navigateTo('search-results')
    }

    async created() {
      const packages = await this.$store.dispatch('packages/fetchPackages')
      let promises: Promise<void>[] = []

      this.loading = 'bases'

      if (packages) {
        promises = _.map(packages, (pkg: PackageAPI) => this.$store.dispatch('bases/fetchBases', pkg.PackageId))
      }

      if (this.$store.getters['global/region'] === null) {
        promises.push(this.$store.dispatch('global/fetchRegion'))
      }

      Promise.all(promises).then(() => {
        this.isLoading = false
      })
    }

    mounted(): void {
      if (this.$route.query.reset) {
        this.resetSearch()
      }

      this.$store.dispatch('search/setMaximumDestinations', this.brandSetting('destinations.maximum'))
      this.$store.dispatch('search/clearBases')
      this.$store.dispatch('capture/deselectResult')
    }
  }
