export class Config {
  get() {
    return {
      domain: 'gohelicopters.co.uk',
      title: 'GoHelicopters',
      currency: 'gbp',
      map: {
        zoom: 10,
        center: {
          lat: 53.934927,
          lng: -2.407734,
        },
        bounds: [
          {lng: -11.0303, lat: 49.838},
          {lng: 2.0654, lat: 59.6677},
        ],
      },
      destinations: {
        maximum: 2,
      },
      passengers: {
        min: 1,
        max: 10,
      },
      costs: {
        minimum: {
          default: 750,
        },
      },

      routeTypes: {
        tour: {
          link: {
            href: 'https://www.adventure001.com/Activity/Aviation/Helicopter-Rides/43',
            text: 'View Scheduled Tours from £39',
          },
        },
      },

      links: {
        brochure: {
          href: '/adventure001/brochure.pdf',
          text: 'Brochure &amp; Contact',
        },
      },
    }
  }
}
