import {Module} from 'vuex'

import ResultInterface from '@/models/result'

export const capture: Module<any, any> = {
  namespaced: true,
  state: {
    selected: null,
  },
  actions: {
    async selectResult({commit}, result: ResultInterface) {
      commit('SELECT_RESULT', result)
    },

    async deselectResult({commit}) {
      commit('DESELECT_RESULT')
    },
  },
  mutations: {
    SELECT_RESULT(state, result: ResultInterface) {
      state.selected = result
    },

    DESELECT_RESULT(state) {
      state.selected = null
    },
  },
}
