import * as _ from 'lodash'
import {Module} from 'vuex'

import axios from '@/plugins/axios'
import moment from '@/plugins/moment'
import BrowserStorage from '@/utils/browser-storage'

let localStorageBases = BrowserStorage.restore('bases')
if (!localStorageBases.updated_at || moment().diff(localStorageBases.updated_at, 'hours') > 24) {
  localStorageBases = {}
}

const initialState: any = {
  bases: localStorageBases.bases || {},
}
const saveToLocalStorage = (state: any) => {
  state.updated_at = moment()
  BrowserStorage.save('bases', state)
}

export const bases: Module<any, any> = {
  namespaced: true,
  state: initialState,
  getters: {
    bases: (state) => state.bases,
    getBasesForPackage: (state) => (packageId: number) => {
      return state.bases[packageId]
    },
  },
  actions: {
    async fetchBases({commit}, packageId: number) {
      if (!_.isEmpty(initialState.bases)) {
        return
      }

      const {data} = await axios.get(`/packages/${packageId}`)

      commit('SET_BASES', {packageId, data})
    },

    setBases({commit}, {packageId, data}) {
      commit('SET_BASES', {packageId, data})
    },
  },
  mutations: {
    SET_BASES(state, {packageId, data}) {
      state.bases[packageId] = data
      saveToLocalStorage(state)
    },
    SET_ALL_BASES(state, {data}) {
      state.bases = data
      saveToLocalStorage(state)
    },
  },
}
