






import Utilities from '@/mixins/utilities'

import {mixins} from 'vue-class-component'
import {Component, Prop} from 'vue-property-decorator'
import {mapGetters} from 'vuex'

@Component({
  computed: {
    ...mapGetters('global', ['currency']),
  },
})
export default class PriceFormat extends mixins(Utilities) {
  @Prop({required: true}) cost: number

  currency!: string

  formatPrice(price: number, currency: string) {
    if (currency === 'eur') {
      price *= 1.2
    }

    const fmt = new Intl.NumberFormat('en-gb', {
      style: 'currency',
      currency: currency.toUpperCase(),
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })

    return fmt.format(price)
  }
}
