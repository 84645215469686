import { render, staticRenderFns } from "./currency-switcher.vue?vue&type=template&id=20b5f8f5&"
import script from "./currency-switcher.vue?vue&type=script&lang=ts&"
export * from "./currency-switcher.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports