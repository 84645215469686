









import Utilites from '@/mixins/utilities'
import {mixins} from 'vue-class-component'
import {Component, Prop} from 'vue-property-decorator'

@Component({})
export default class Passengers extends mixins(Utilites) {
  @Prop({required: true}) passengers: number
  @Prop({required: false, default: null}) maxPassengers: number | null

  setPassengers(pax: number) {
    this.$emit('update:passengers', pax)
  }
}
