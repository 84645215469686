






import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'

import _ from 'lodash'

import LoadingOverlay from '@/components/loading-overlay.vue'
import Utilities from '@/mixins/utilities'
import {PackageAPI} from '@/models/package'
import BrandProvider from '@/utils/brand-provider'

@Component({
  components: {
    LoadingOverlay,
  },
  watch: {
    '$route'(to: any) {
      document.title = `${this.brandSetting('title')} - ${to.meta.title || 'home'}`
    },
  },
})
export default class App extends mixins(Utilities) {
  isLoading: boolean = true
  loading: string = 'packages'

  beforeMount() {
    BrandProvider.styles()
  }

  async created() {
    this.setFavicon()
  }

  async setFavicon() {
    const link = document.createElement('link')
    const oldLink = document.getElementById('dynamic-favicon') as HTMLLinkElement
    const faviconUrl = this.brandAssetUrl('favicon.ico')

    if (!faviconUrl) {
      return
    }

    link.id = oldLink.id
    link.rel = oldLink.rel
    link.href = faviconUrl

    if (oldLink) {
      document.head.removeChild(oldLink)
    }
    document.head.appendChild(link)
  }
}
