








































































import DestinationInterface from '@/models/destination'
import {PackageAPI as PackageAPIInterface} from '@/models/package'
import ResultInterface from '@/models/result'

import Utilities from '@/mixins/utilities'

import * as turf from '@turf/turf'
import {latLngBounds, LatLngLiteral} from 'leaflet'
import * as _ from 'lodash'
import {mixins} from 'vue-class-component'
import {Component, Watch} from 'vue-property-decorator'
import {LCircle, LFeatureGroup, LGeoJson, LIcon, LLayerGroup, LMap, LPolyline, LPopup} from 'vue2-leaflet'
import {mapGetters, mapState} from 'vuex'

const DefaultZoom = 10
// covers UK
const DefaultBounds = [
  {lng: -11.0303, lat: 49.838},
  {lng: 2.0654, lat: 59.6677},
]

@Component({
  components: {
    LIcon,
    LCircle,
    LGeoJson,
    LFeatureGroup,
    LLayerGroup,
    LPolyline,
    LPopup,
  },
  computed: {
    ...mapGetters('search', [
      'destinations',
      'bases',
      'routeType',
      'duration',
    ]),
    ...mapState('packages', ['packages']),
    ...mapState('capture', ['selected']),
    line() {
      return _.map(this.destinations, (destination: DestinationInterface) => ({lat: destination.lat, lng: destination.lng}))
    },
    mapMarkerBounds() {
      if (this.destinations.length === this.bases.length && this.destinations.length === 0) {
        return null
      }

      const destinationBounds = latLngBounds(_.map(this.destinations, (destination) => ({
        lat: destination.lat,
        lng: destination.lng,
      } as LatLngLiteral)))

      let bases = this.bases.slice(0)
      if (this.selected) {
        // return only route + selected base
        bases = _.filter(bases, base => base.index === this.selected.index)
      }

      const baseBounds = latLngBounds(_.map(bases, (base) => ({
        lat: base.base.CMPLatitude,
        lng: base.base.CMPLongitude,
      } as LatLngLiteral)))

      if (this.selected && this.routeType === 'tour') {
        const radius = _.find(this.rangeCircles, circle => circle.packageId === this.selected.package.id)
        const circleFeature = turf.feature({type: 'Point', coordinates: [this.destinations[0].lng, this.destinations[0].lat]}, {radius: radius.distance})
        const circleBounds = turf.bbox(circleFeature)

        baseBounds.extend([[circleBounds[1], circleBounds[0]], [circleBounds[3], circleBounds[2]]])
      }

      return destinationBounds.extend(baseBounds).pad(0.5)
    },
  },
  data() {
    return {
      rangeCircles: null,
      zoom: null,
      center: null,
    }
  },
})
export default class ResultsMap extends mixins(Utilities) {
  DefaultCenter: LatLngLiteral
  DefaultZoom: number

  accessToken: string = 'pk.eyJ1IjoiaG91c2VhbGV4YW5kZXIiLCJhIjoiY2szbmxpN2h4MGRodTNwbDc2bjYyYW83diJ9.IGvqUzxU6dymPL-XYRbVxw'
  mapUrl: string = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${this.accessToken}`
  center: any
  zoom: number
  defaultBounds: any = null
  mapMarkerBounds!: any
  packages!: PackageAPIInterface[]
  duration!: number
  selected!: ResultInterface
  routeType!: string
  bases!: any[]

  rangeCircles: any[] | null

  beforeMount() {
    this.DefaultCenter = this.brandSetting('map.center', {
      lat: 53.934927,
      lng: -2.407734,
    })
    this.DefaultZoom = this.brandSetting('map.zoom', 10)

    this.center = {...this.DefaultCenter}
    this.zoom = parseFloat(this.DefaultZoom as unknown as string)
  }

  circleColor(circle: any): string {
    if (this.selected && this.selected.package.id === circle.packageId) {
      return 'rgba(255, 0, 0, 1)'
    }

    return this.selected ? 'rgba(255, 0, 0, 0.1)' : 'rgba(255, 0, 0, 0.5)'
  }

  baseClass(base: any): string | void {
    if (this.selected && this.selected.index !== base.index) {
      return 'opacity-25'
    }
  }

  changeRouteType(): void {
    if (this.routeType === 'tour' && this.duration && this.packages && this.bases.length) {
      // add circles
      this.rangeCircles = this.packages.map(pkg => {
        const distance = (parseInt(pkg.AverageCruisingSpeed, 10) * (this.duration / 60)) / 2

        return {
          packageId: pkg.PackageId,
          aircraft: pkg.ServiceDescription.replace(/(Helicopter)? Charter/gi, '').trim(),
          distance: turf.convertDistance(distance, 'nauticalmiles', 'meters'),
        }
      }) || []
    } else {
      this.rangeCircles = []
    }
  }

  @Watch('bases', {deep: true})
  @Watch('destinations', {deep: true})
  @Watch('selected')
  refresh() {
    this.$nextTick(() => {
      this.changeRouteType()

      const markerBounds = this.mapMarkerBounds
      const resultMap: LMap = this.$refs.resultMap as LMap

      if (markerBounds) {
        this.center = markerBounds.getCenter()
      } else {
        this.center = {...this.DefaultCenter}
        this.zoom = parseFloat(this.DefaultZoom as unknown as string)
      }

      if (resultMap) {
        if (markerBounds) {
          resultMap.mapObject.fitBounds(markerBounds, {maxZoom: parseFloat(this.DefaultZoom as unknown as string)})
        } else {
          resultMap.mapObject.fitBounds(this.defaultBounds, {maxZoom: DefaultZoom})
        }

        resultMap.mapObject.invalidateSize()
      }
    })
  }

  mounted() {
    this.defaultBounds = this.brandSetting('map.bounds', DefaultBounds.slice())
    this.refresh()
  }
}
