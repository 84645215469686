import * as Sentry from '@sentry/browser'
import axios from 'axios'
import _ from 'lodash-es'
import Vue from 'vue'
import Component from 'vue-class-component'

import BrandProvider from '@/utils/brand-provider'
import moment from 'moment'

/*
 * Contains helper utilities used across all vue components.
 */
@Component
export default class Utilities extends Vue {
  get brandConfig(): any {
    return BrandProvider.config()
  }

  get brandName(): string {
    return BrandProvider.get()
  }

  get brandSlug(): string {
    return _.kebabCase(this.brandName).replace(/_([0-9]+)/g, '$1').replace(/([0-9]+)_/g, '$1')
  }

  get environment(): string {
    return process.env.NODE_ENV || 'production'
  }

  get globalTimes(): string[] {
    const m = moment().startOf('day')
    const end = moment().endOf('day')
    const ret = []

    do {
      ret.push(m.format('HH:mm'))
      m.add(15, 'minutes')
    }
    while (m.isBefore(end))

    return ret
  }

  get isDevelopment(): boolean {
    return process.env.NODE_ENV === 'development'
  }

  get isProduction(): boolean {
    return process.env.NODE_ENV === 'production'
  }

  get mapboxAxios(): any {
    return axios.create({
      baseURL: 'https://api.mapbox.com',
    })
  }

  get captureAxios(): any {
    return axios.create({
      baseURL: 'https://adv001.azurewebsites.net/clientApi/v1/',
    })
  }

  get tourTimes(): number[] {
    return [15, 20, 30, 40, 60, 90]
  }

  get version(): string {
    return process.env.VUE_APP_VERSION || 'n/a'
  }

  brandSetting(key: string, otherwise?: any): any {
    return _.get(this.brandConfig, key) ?? otherwise
  }

  brandAssetUrl(src: string): string | null {
    try {
      return require(`@/assets/${this.brandSlug}/${src}`)
    } catch (e) {
      return null
    }
  }

  isBrand(brand: string): boolean {
    return this.brandName === brand
  }

  isStage(stage: string): boolean {
    return this.$router.currentRoute.name === `search-${stage}`
  }

  scrollTo(refName: string): void {
    this.$nextTick(() => {
      document.getElementsByName(refName)[0].scrollIntoView()
    })
  }

  navigateTo(page: string): void {
    this.$router.push({name: page})
  }

  resetSearch(): void {
    this.$nextTick(() => {
      this.$store.dispatch('search/reset')
      this.$store.dispatch('search/clearBases')
      this.$store.dispatch('search/clearDestinations')
      if (this.$router.currentRoute.name !== 'search-form') {
        this.$router.replace({name: 'search-form'})
      }
    })
  }

  sentry(err: Error, context?: any): void {
    if (context) {
      Sentry.setExtras(context)
    }

    Sentry.captureException(err)
  }

  updateSentryContext(key: string, context: any): void {
    Sentry.setContext(key, context)
  }
}
