




















import Utilites from '@/mixins/utilities'
import {DateTime} from '@/models/date-time'

import {mixins} from 'vue-class-component'
import {Component, Prop, Watch} from 'vue-property-decorator'

@Component
export default class DateTimeSelector extends mixins(Utilites) {
  @Prop({required: true}) label: string
  @Prop({default: false}) disabled: boolean
  @Prop({required: true}) datetime: DateTime | null
  @Prop({default: true}) canArriveBy: boolean

  model: DateTime = {
    case: null,
    time: null,
  }

  touched: boolean = false

  preselectTime() {
    if (this.model.time === null) {
      this.$nextTick(() => {
        const elem = this.$el.querySelector('.vs__dropdown-menu') as HTMLElement
        if (elem && elem.scrollTo) {
          elem.scrollTo({top: elem.scrollHeight / 2})
        }
        this.model.time = '12:30'
      })
    } else {
      this.$nextTick(() => {
        const elem = this.$el.querySelector('.vs__dropdown-menu') as HTMLElement
        const selected = elem.querySelector('.vs__dropdown-option--selected') as HTMLElement
        if (elem && selected && elem.scrollTo) {
          elem.scrollTo({top: selected.offsetTop})
        }
      })
    }
  }

  deselectTime() {
    if (!this.touched) {
      this.model.time = null
    }
  }

  changeTime() {
    this.touched = this.model.time !== null
    if (this.model.case === null) {
      this.model.case = 'depart_at'
    }
  }

  @Watch('model', {deep: true})
  updateModel() {
    this.$emit('update:datetime', this.model)
  }
}
