











import Utilities from '@/mixins/utilities'

import {mixins} from 'vue-class-component'
import {Component, Prop} from 'vue-property-decorator'

@Component
export default class LoadingOverlay extends mixins(Utilities) {
  @Prop({required: true}) loading: string
}
