

















import Utilities from '@/mixins/utilities'
import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'

@Component
export default class Success extends mixins(Utilities) {
}
