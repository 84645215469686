import * as _ from 'lodash'

import {bases} from '@/store/bases'

import BaseInterface, {BaseAPI} from '@/models/base'
import {PackageAPI} from '@/models/package'
import {LineString, Position} from '@turf/helpers'
import * as turf from '@turf/turf'
import {convertDistance} from '@turf/turf'

export default class BaseCalculator {
  pkg: PackageAPI
  destinations: BaseInterface[]
  lineString: LineString
  distance: number
  closest: any
  contiguous: string | null = null

  constructor(pkg: PackageAPI, destinations: BaseInterface[]) {
    this.pkg = pkg
    this.destinations = destinations

    if (destinations.length > 1) {
      this.lineString = {
        type: 'LineString',
        coordinates: destinations.map((destination: BaseInterface) => {
          return [destination.lng, destination.lat] as Position
        }),
      }
    }
  }

  get venuesList(): any {
    return _.get(bases.state.bases, this.pkg.PackageId, [])
  }

  get originDestination(): number[] {
    const destination = this.destinations[0]
    return [destination.lng, destination.lat]
  }

  setContiguous(contiguous: string | null): this {
    this.contiguous = contiguous
    return this
  }

  identify(routeType?: string): any {
    if (this.closest) {
      return this.closest
    }

    let distance: number = Infinity
    let closest: BaseAPI | null = null

    _.each(this.venuesList, (venue: BaseAPI) => {
      if (!this.venueCanCompleteRoute(venue)) {
        return
      }

      const baseDistance = this.calculateDistance(venue, routeType)
      if (baseDistance < distance) {
        distance = baseDistance
        closest = venue
      }
    })

    this.distance = convertDistance(distance, 'kilometers', 'nauticalmiles')

    return this.closest = closest
  }

  calculateDistance(venue: BaseAPI, routeType?: string): number {
    if (!venue.CMPLatitude || !venue.CMPLongitude) {
      return Infinity
    }

    const venuePoint = turf.point([venue.CMPLongitude, venue.CMPLatitude], {units: 'nauticalmiles'}) as any

    if (this.lineString && routeType === 'one-way') {
      return turf.pointToLineDistance(
        venuePoint,
        this.lineString,
        {units: 'nauticalmiles'}
      )
    }

    return turf.distance(venuePoint, turf.point([this.destinations[0].lng, this.destinations[0].lat], {units: 'nauticalmiles'}))
  }

  venueCanCompleteRoute(venue: BaseAPI): boolean {
    if (venue.TwinEngine) {
      return true
    }

    if (this.contiguous !== null) {
      // return
      // need to check if a flight from the base to the nearest point on the route would cross and boundaries
      const venuePoint = [venue.CMPLongitude, venue.CMPLatitude]
      const landmass = this.contiguous === 'ireland' ? 'england-wales-scotland' : 'ireland'

      return !turf.booleanContains(require(`@/data/${landmass}.geo.min.json`).features[0], turf.point(venuePoint))
    }

    return venue.TwinEngine ? true : this.contiguous !== null
  }
}
