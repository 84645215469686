




































































import CurrencySwitcher from '@/components/results/currency-switcher.vue'
import Result from '@/components/results/result.vue'
import DateTimeSelector from '@/components/sidebar/date-time-selector.vue'
import Passengers from '@/components/sidebar/passengers.vue'
import ResetSearch from '@/components/sidebar/reset-search.vue'

import Utilities from '@/mixins/utilities'

import AircraftInterface from '@/models/aircraft'
import PackageInterface, {PackageAPI} from '@/models/package'
import ResultInterface from '@/models/result'
import SearchData from '@/models/search-data'

import DistanceCalculator from '@/utils/calculators/distance.calculator'

import {mixins} from 'vue-class-component'
import {Component, Watch} from 'vue-property-decorator'

import BaseCalculator from '@/utils/calculators/base.calculator'
import RouteCalculator from '@/utils/calculators/route.calculator'
import {convertDistance} from '@turf/turf'
import _ from 'lodash'

@Component({
  components: {
    CurrencySwitcher,
    ResetSearch,
    Result,
    Passengers,
    DateTimeSelector,
  },
  watch: {
    search: {
      deep: true,
      handler(updated) {
        this.$store.dispatch('search/update', updated)
      },
    },
  },
})
export default class Results extends mixins(Utilities) {
  search: SearchData

  distance: number
  showFilters = false
  maxPassengers: number = 1
  isLoading: boolean = true
  results: ResultInterface[] = []
  sortedResults: ResultInterface[] = []
  selected: ResultInterface | null = null

  get isReturn(): boolean {
    return this.search.routeType === 'return'
  }

  get isTour(): boolean {
    return this.search.routeType === 'tour'
  }

  get days(): number {
    return 1
  }

  get distanceInMiles(): number {
    return Math.round(convertDistance(this.distance, 'nauticalmiles', 'miles'))
  }

  calculateDistance(): number {
    const calc = new DistanceCalculator
    calc.setDestinations(this.search.destinations)

    let distance = calc.calculate()

    if (this.search.routeType === 'return') {
      distance *= 2
    }

    this.$store.dispatch('search/setDistances', calc.distances)

    return distance
  }

  @Watch('results', {deep: true})
  sortResults(): void {
    this.sortedResults = _.orderBy(this.results, ['cost', 'index'], ['asc', 'asc'])
  }

  mounted() {
    this.search = this.$store.state.search.searchData
    this.updateSentryContext('search', this.search)

    this.distance = this.calculateDistance()
    this.calculateResults()
  }

  calculateResults() {
    this.isLoading = true

    const bases: any[] = []

    const routeCalc = new RouteCalculator(this.search.destinations).setRouteType(this.search.routeType)
    this.search.contiguous = routeCalc.isContiguous()

    this.results = this.$store.state.packages.packages.map((pkg: PackageAPI, index: number) => {
      const baseCalc = new BaseCalculator(pkg, this.search.destinations)
      let base
      if (!this.$store.getters['search/twinOnly'] || pkg.TwinEngine) {
        base = baseCalc.setContiguous(routeCalc.contiguousWithin).identify(this.search.routeType)
      }

      if (base) {
        if (!bases[base.VenueId]) {
          bases[base.VenueId] = {base, index: Object.keys(bases).length + 1, aircraft: []}
        }
        bases[base.VenueId].aircraft.push(pkg.PackageName.replace(' Helicopter Charter', ''))
      }

      return {
        index: base ? bases[base.VenueId].index : null,
        package: {
          id: pkg.PackageId,
          name: pkg.PackageName.replace(' Helicopter Charter', ''),
          code: pkg.Code,
          description: pkg.AircraftDescription,
          aircraftType: pkg.ServiceDescription.replace(/(Helicopter)? Charter/gi, '').trim(),
        } as PackageInterface,
        aircraft: {
          cruiseRate: pkg.AverageCruisingSpeed || 120,
          passengers: pkg.SeatsPassengers,
          imageUrl: pkg.SamplePhotoUrl ? `${pkg.PhotoUrlBase}${pkg.SamplePhotoUrl}` : null,
          twinEngine: pkg.TwinEngine,
        } as AircraftInterface,
        base: {
          closest: base,
          distance: baseCalc.distance,
        },
      } as ResultInterface
    })

    this.$store.dispatch('search/setBases', _.filter(bases))

    this.maxPassengers = _.max(_.map(this.results, 'aircraft.passengers')) as number
    this.isLoading = false
  }

  selectResult(result: ResultInterface | null): void {
    this.updateSentryContext('selected', result)
    this.selected = result
    this.$store.dispatch('capture/selectResult', result)
  }

  clearResult(result: ResultInterface): void {
    if (this.selected === result) {
      this.selectResult(null)
    }
  }
}
