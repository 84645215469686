























































import Capture from '@/components/capture.vue'
import Results from '@/components/results.vue'
import SearchForm from '@/components/search-form.vue'
import ResetSearch from '@/components/sidebar/reset-search.vue'

import Utilities from '@/mixins/utilities'

import ResultInterface from '@/models/result'

import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'

@Component({
  components: {
    ResetSearch,
    Capture,
    Results,
    SearchForm,
  },
})
export default class Sidebar extends mixins(Utilities) {
  selected: ResultInterface
}
