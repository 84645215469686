export default class BrowserStorage {
  static save(key: string, value: any): void {
    if (typeof value !== 'string') {
      value = JSON.stringify(value)
    }

    try {
      localStorage.setItem(key, value)
    } catch (err) {
      try {
        sessionStorage.setItem(key, value)
      } catch (err) {
        return
      }
    }
  }

  static restore(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key) as string) || {}
    } catch (err) {
      try {
        return JSON.parse(sessionStorage.getItem(key) as string) || {}
      } catch (err) {
        return {}
      }
    }
  }
}
