import Vue from 'vue'
import Vuex from 'vuex'

import {bases} from '@/store/bases'
import {capture} from '@/store/capture'
import {global} from '@/store/global'
import {packages} from '@/store/packages'
import {search} from '@/store/search'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    bases,
    capture,
    global,
    packages,
    search,
  },
})
