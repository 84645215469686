



















































































import PriceFormat from '@/components/results/price-format.vue'

import Utilities from '@/mixins/utilities'

import ResultInterface from '@/models/result'

import CostCalculator from '@/utils/calculators/cost.calculator'
import RouteCalculator from '@/utils/calculators/route.calculator'

import moment from 'moment'
import {mixins} from 'vue-class-component'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {mapGetters} from 'vuex'

@Component({
  components: {
    PriceFormat,
  },
  computed: {
    ...mapGetters('search', {
      filteredPassengers: 'passengers',
    }),
  },
})
export default class Result extends mixins(Utilities) {
  @Prop({required: true}) result: ResultInterface
  @Prop({required: false}) distance: number
  @Prop({required: false}) days: number
  @Prop({required: true}) selected: boolean
  @Prop({default: 'results'}) context: string

  filteredPassengers!: number
  duration: number = 0
  baseDistance: number = 0

  isCalculating: boolean = true

  get formattedTime(): string {
    const duration = moment.duration(this.duration, 'hours')
    const output = []

    if (duration.hours() > 0) {
      output.push(duration.hours() + 'h')
    }

    if (duration.seconds() > 29) {
      duration.add(1, 'minute')
    }

    if (duration.minutes() > 0) {
      output.push(duration.minutes() + 'm')
    }

    return output.join(' ')
  }

  get disabled(): boolean {
    return this.filteredPassengers > this.result.aircraft.passengers || (this.result.index === null || this.result.base.closest === null)
  }

  mounted() {
    this.updateSentryContext('result', this.result)
    this.calculatePrice()
  }

  @Watch('result', {deep: true})
  calculatePrice() {
    const routeCalc = new RouteCalculator(this.$store.getters['search/destinations'])
    routeCalc
      .setRouteType(this.$store.getters['search/routeType'])
      .setCruiseRate(this.result.aircraft.cruiseRate)
      .setBase(this.result.base.closest)

    const costCalc = new CostCalculator(this.result).setType(this.$store.getters['search/routeType'])

    if (this.result.calculated) {
      this.baseDistance = this.result.calculated.baseDistance
      this.duration = this.result.calculated.hours
      routeCalc.setDuration(this.duration)
    } else if (this.result.base.closest) {
      if (this.$store.getters['search/routeType'] === 'tour') {
        routeCalc.setDuration(this.$store.getters['search/duration'])
      }

      const route = routeCalc.calculateRoute()
      costCalc
        .setSearchData(this.$store.state.search.searchData)
        .setRoute(route)

      this.duration = routeCalc.journeyTime

      this.result.cost = Math.max(
        costCalc.calculate(),
        this.result.base.closest.MinimumPrice
      )
      this.baseDistance = routeCalc.baseDistance

      this.result.calculated = {
        hours: this.duration,
        packageCost: this.result.base.closest.MachineCost,
        pilotRate: this.result.base.closest.PilotCost,
        days: this.days,
        distance: this.distance,
        baseDistance: this.baseDistance,
      }

      this.$emit('update:result', this.result)
    }

    this.isCalculating = false
  }

  @Watch('disabled')
  clearResult(): void {
    this.$emit('clear', this.result)
  }

  clickResult(): void {
    if (!this.disabled) {
      this.$emit('click', this.result)
    }
  }
}
