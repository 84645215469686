import BaseInterface, {BaseAPI} from '@/models/base'
import LegInterface from '@/models/leg'

import DistanceCalculator from '@/utils/calculators/distance.calculator'
import TimeCalculator from '@/utils/calculators/time.calculator'

import {LineString, Point, Position} from '@turf/helpers'
import * as turf from '@turf/turf'
import * as _ from 'lodash'

export default class RouteCalculator {
  destinations: BaseInterface[]
  ews: any
  ireland: any
  routeType: string
  legs: LegInterface[]
  duration: number | undefined
  cruiseRate: number
  base: BaseAPI | undefined
  baseDistance: number = 0
  journeyTime: number

  constructor(destinations: any) {
    this.destinations = destinations
    this.ireland = require('@/data/ireland.geo.min.json').features[0]
    this.ews = require('@/data/england-wales-scotland.geo.min.json').features[0]
  }

  get contiguousWithin(): string | null {
    const isTour = this.routeType === 'tour'

    if (turf.booleanContains(this.ireland, this.lineString) && (isTour || (!isTour && !turf.booleanCrosses(this.lineString, this.ireland)))) {
      return 'ireland'
    }

    if (turf.booleanContains(this.ews, this.lineString) && (isTour || (!isTour && !turf.booleanCrosses(this.lineString, this.ews)))) {
      return 'ews'
    }

    return null
  }

  get lineString(): LineString | Point {
    if (this.destinations.length === 1) {
      return {
        type: 'Point',
        coordinates: [this.destinations[0].lng, this.destinations[0].lat],
      }
    }
    return {
      type: 'LineString',
      coordinates: this.destinations.map((destination: BaseInterface) => {
        return [destination.lng, destination.lat] as Position
      }),
    }
  }

  setBase(base?: BaseAPI): this {
    this.base = base

    return this
  }

  setRouteType(routeType: string): this {
    this.routeType = routeType

    return this
  }

  setDuration(duration?: number): this {
    this.duration = duration
    return this
  }

  setCruiseRate(cruiseRate: number): this {
    this.cruiseRate = cruiseRate

    return this
  }

  isContiguous(): boolean {
    return this.contiguousWithin !== null
  }

  calculateRoute(): LegInterface[] {
    switch (this.routeType) {
      case 'tour':
        this.legs = this.getRouteLegsTour()
        break
      case 'one-way':
        this.legs = this.getRouteLegsOneWay()
        break
      case 'return':
        this.legs = this.getRouteLegsReturn()
        break
    }

    this.journeyTime = _.sumBy(this.legs, 'ete')

    this.addBaseLegs()

    return this.legs = this.legs.map((leg: LegInterface, index: number) => {
      leg.legNumber = index
      return leg
    })
  }

  private addBaseLegs() {
    const distCalc = new DistanceCalculator
    const timeCalc = new TimeCalculator
    const base = this.base as BaseAPI

    // always start with the base
    if (this.legs[0].start?.venueId !== base.VenueId) {
      const baseDistance = distCalc.distanceBetween({lng: base.CMPLongitude, lat: base.CMPLatitude}, {lng: this.legs[0].start?.lng as number, lat: this.legs[0].start?.lat as number})
      this.baseDistance += baseDistance
      this.legs.unshift({
        legNumber: 0,
        distance: baseDistance,
        ete: timeCalc.calculate(baseDistance, this.cruiseRate),
        start: {
          lat: base.CMPLatitude,
          lng: base.CMPLongitude,
          venueId: base.VenueId,
        },
        end: {...this.legs[0].start},
      } as LegInterface)
    }

    // finally need to get back to the base, if we're not there already
    const lastLeg = this.legs[this.legs.length - 1]
    if (lastLeg.end.venueId !== base.VenueId) {
      const baseDistance = distCalc.distanceBetween({lng: lastLeg.end.lng, lat: lastLeg.end.lat}, {lng: base.CMPLongitude, lat: base.CMPLatitude})
      this.baseDistance += baseDistance
      this.legs.push({
        legNumber: 99,
        distance: baseDistance,
        ete: timeCalc.calculate(baseDistance, this.cruiseRate),
        start: {...lastLeg.end},
        end: {
          lat: base.CMPLatitude,
          lng: base.CMPLongitude,
          venueId: base.VenueId,
        },
      } as LegInterface)
    }
  }

  private getRouteLegsTour(): LegInterface[] {
    return [{
      legNumber: 1,
      distance: null,
      ete: parseFloat((this.duration as number / 60).toFixed(1)),
      start: {
        venueId: this.destinations[0].venueId,
        lat: this.destinations[0].lat,
        lng: this.destinations[0].lng,
      },
      end: {
        venueId: this.destinations[0].venueId,
        lat: this.destinations[0].lat,
        lng: this.destinations[0].lng,
      },
    } as LegInterface]
  }

  private getRouteLegsOneWay(): LegInterface[] {
    return this.getRouteLegs(this.destinations)
  }

  private getRouteLegsReturn(): LegInterface[] {
    return this.getRouteLegs(this.destinations).concat(this.getRouteLegs([...this.destinations].reverse()))
  }

  private getRouteLegs(destinations: BaseInterface[]): LegInterface[] {
    const timeCalc = new TimeCalculator
    const distCalc = new DistanceCalculator
    const legs: LegInterface[] = []

    const length = destinations.length
    for (let index = 1; index < length; ++index) {
      const leg = destinations[index]
      const prevLeg = destinations[index - 1]
      const distance = distCalc.distanceBetween({lat: leg.lat, lng: leg.lng}, {lat: prevLeg.lat, lng: prevLeg.lng})

      legs.push({
        legNumber: 0,
        distance,
        ete: distance > 0 ? timeCalc.calculate(distance, this.cruiseRate) : null,
        start: {
          lat: prevLeg.lat,
          lng: prevLeg.lng,
          venueId: prevLeg.venueId,
        },
        end: {
          lat: leg.lat,
          lng: leg.lng,
          venueId: leg.venueId,
        },
      } as LegInterface)
    }

    return legs
  }
}
