














import ResultsMap from '@/components/results-map.vue'
import Sidebar from '@/components/sidebar.vue'
import Utilities from '@/mixins/utilities'
import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'

@Component({
  components: {
    Sidebar,
    ResultsMap,
  },
})
export default class Quotation extends mixins(Utilities) {
}
