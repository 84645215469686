import Capture from '@/components/capture.vue'
import Results from '@/components/results.vue'
import SearchForm from '@/components/search-form.vue'

import store from '@/store'
import BrandProvider from '@/utils/brand-provider'

import Home from '@/views/home.vue'
import Quotation from '@/views/quotation.vue'
import Success from '@/views/success.vue'
import UhOh from '@/views/uh-oh.vue'

import Vue from 'vue'
import VueRouter from 'vue-router'

import * as _ from 'lodash'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:brand?',
    name: 'home',
    component: Home,
  },
  {
    path: '/:brand/quotation',
    name: 'quotation',
    component: Quotation,
    redirect: '/quotation/search',
    children: [
      {
        component: SearchForm,
        name: 'search-form',
        path: 'search',
        meta: {
          title: 'New quotation',
        },
      },
      {
        component: Results,
        name: 'search-results',
        path: 'results',
        meta: {
          title: 'Quotation results',
          requiresSearchData: true,
        },
      },
      {
        component: Capture,
        name: 'search-capture',
        path: 'capture',
        meta: {
          title: 'Contact details',
          requiresResult: true,
        },
      },
    ],
  },
  {
    path: '/:brand/success',
    name: 'success',
    component: Success,
    meta: {
      title: 'Success!',
    },
  },
  {
    path: '/error',
    name: 'error',
    component: UhOh,
    meta: {
      title: 'Uh oh',
    },
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (to.hash) {
          return resolve({selector: to.hash})
        } else if (savedPosition) {
          return resolve(_.merge(savedPosition, {selector: '#sidebar'}))
        } else {
          return resolve({x: 0, y: 0})
        }
      }, 500)
    })
  },
})

router.beforeEach((to, from, next) => {
  if (to.params.brand && BrandProvider.brand !== to.params.brand) {
    BrandProvider.set(to.params.brand)
  }

  if (to.name !== 'error' && to.name !== 'home' && !BrandProvider.get(false)) {
    return next({name: 'error'})
  }

  if (!to.params.brand && BrandProvider.isValid) {
    to.params.brand = BrandProvider.get()
  }

  if (to.matched.some(({meta}) => meta.requiresSearchData) && !store.getters['search/touched']) {
    return next({params: {brand: BrandProvider.get(false)}, name: 'search-form'})
  }

  if (to.matched.some(({meta}) => meta.requiresResult) && !store.state.capture.selected) {
    return next({params: {brand: BrandProvider.get(false)}, name: 'search-form'})
  }

  next()
})

export default router
