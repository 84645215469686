










































































import Utilities from '@/mixins/utilities'

import BaseInterface, {VenueLookupAPI} from '@/models/base'
import {mixins} from 'vue-class-component'
import {Component, Prop} from 'vue-property-decorator'
import {AxiosError} from 'axios'

interface VenueGrouping {
  group: string
  bases: BaseInterface[]
}

@Component
export default class Destination extends mixins(Utilities) {
  @Prop({required: true}) destination: BaseInterface
  @Prop({required: true}) destinationIndex: number
  @Prop({required: false, default: 'Destination'}) placeholder: string

  locations: VenueGrouping[] = []
  isLoading: boolean = false
  error: boolean | string = false
  inputTimeout: number

  get thisDestination(): BaseInterface {
    return this.destination
  }

  set thisDestination(destination: BaseInterface) {
    this.updateSentryContext(`destination-${this.destinationIndex}`, destination)
    this.$emit('update:destination', destination)
    this.$emit('input', destination)
  }

  get firstDestination(): VenueGrouping[] {
    const destination = this.$store.getters['search/firstDestination']
    if (this.destinationIndex > 1 && destination) {
      return [
        {
          group: 'Back to the start',
          bases: [destination as BaseInterface],
        },
      ]
    }

    return []
  }

  input() {
    this.$emit('input', ...arguments)
  }

  mounted() {
    this.locations = this.firstDestination
  }

  async delayInput(search: any, loading: (loading: boolean) => void) {
    if (this.inputTimeout) {
      clearTimeout(this.inputTimeout)
    }

    this.inputTimeout = setTimeout(() => {
      this.fetchLocations(search, loading)
    }, 200)
  }

  async fetchLocations(search: any, loading: (loading: boolean) => void) {
    this.updateSentryContext('raw_search_string', search)

    const escaped = escape(search.replace(/\s/g, '+'))
    this.error = false

    if (escaped.length < 3) {
      this.locations = []
      return
    }

    this.isLoading = true

    const onerror = (err: AxiosError) => {
      this.error = 'Oh no! We had a problem making that request! We\'ve been notified of this error and will try to fix it as soon as we can. Please try again later.'
      this.sentry(err, {context: 'location_lookup', code: err.code, ...err.response})

      return null
    }

    const requests = []
    requests.push(this.mapboxAxios.get(`/geocoding/v5/mapbox.places/${escaped}.json`, {
      params: {
        access_token: 'pk.eyJ1IjoiaG91c2VhbGV4YW5kZXIiLCJhIjoiY2szbmxpN2h4MGRodTNwbDc2bjYyYW83diJ9.IGvqUzxU6dymPL-XYRbVxw',
        autocomplete: true,
        limit: 5,
        country: this.brandSetting('countries', 'gb,ie,im,fr'),
        types: 'postcode,place,address,poi',
      },
    }).then((resp: any) => resp.data).catch(onerror))

    requests.push(this.axios.get(`/venues/${escaped}`).then((resp: any) => resp.data).catch(onerror))

    const [geocoded, known] = await Promise.all(requests)

    this.locations = [
      ...this.firstDestination,
      {
        group: 'Recommended sites',
        bases: known.filter((venue: VenueLookupAPI) => {
          return venue.Name !== '' && venue.Postcode !== '' && !venue.IsPrivate && venue.CMPLatitude !== 0 && venue.CMPLongitude !== 0
        }).map((venue: VenueLookupAPI) => {
          return {
            destinationIndex: this.destinationIndex,
            postcode: venue.Postcode,
            address: venue.Postcode,
            venueName: venue.Name,
            id: venue.VenueHelipadId,
            venueId: venue.VenueId,
            helipadId: venue.CharterHelipadId,
            lat: venue.CMPLatitude,
            lng: venue.CMPLongitude,
            official: true,
            twinEngineRequired: venue.TwinEngineOnly,
          } as BaseInterface
        }),
      },
      {
        group: 'May require approval',
        bases: geocoded ? geocoded.features.map((feature: any) => {
          feature = feature as GeoJSON.Feature

          const [lng, lat] = feature.geometry.coordinates
          const postcodeContext = feature.context.find((ctx: any) => ctx.id.indexOf('postcode.') > -1)
          return {
            destinationIndex: this.destinationIndex,
            postcode: postcodeContext ? postcodeContext.text : null,
            address: feature.place_name,
            venueName: feature.text,
            id: feature.id,
            lat,
            lng,
            official: false,
          } as BaseInterface
        }) : [],
      },
    ]

    this.updateSentryContext(`destinations-${this.destinationIndex}`, this.locations)

    this.isLoading = false
  }
}
