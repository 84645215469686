



















































































import Result from '@/components/results/result.vue'
import ResetSearch from '@/components/sidebar/reset-search.vue'

import Utilities from '@/mixins/utilities'

import BaseInterface, {BaseAPI} from '@/models/base'
import {CaptureAPI, CharterLegLocation, CharterRouteLeg} from '@/models/capture'
import ResultInterface from '@/models/result'
import SearchData from '@/models/search-data'

import moment from '@/plugins/moment'

import DistanceCalculator from '@/utils/calculators/distance.calculator'
import TimeCalculator from '@/utils/calculators/time.calculator'

import * as _ from 'lodash'
import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'
import {mapState} from 'vuex'

@Component({
  components: {
    ResetSearch,
    Result,
  },
  computed: {
    ...mapState('capture', [
      'selected',
    ]),
    ...mapState('search', [
      'searchData',
    ]),
  },
  data() {
    return {
      lead: {
        forename: '',
        surname: '',
        mobile: '',
        email: '',
        charterComment: '',
      },
    }
  },
})
export default class Capture extends mixins(Utilities) {
  selected!: ResultInterface
  searchData!: SearchData
  submitting: boolean = false

  lead: {
    forename: string,
    surname: string,
    mobile: string,
    email: string,
    charterComment: string,
  }

  get buttonDisabled(): boolean {
    return this.lead.forename.length === 0 || this.lead.surname.length === 0 || this.lead.mobile.length === 0 || this.lead.email.length === 0 || this.submitting
  }

  routeTypeIs(routeType: string): boolean {
    return this.searchData.routeType === routeType
  }

  async onSubmit() {
    if (!this.selected.calculated) {
      return
    }

    if (this.submitting) {
      return
    }

    this.submitting = true

    const data: CaptureAPI = {
      packageId: this.selected.package.id,
      title: ' ',
      ...this.lead,
      landLine: this.lead.mobile,
      CharterQuote: {
        charterFlyingHours: this.selected.calculated.hours.toFixed(1),
        charterPackageCost: this.selected.calculated.packageCost,
        charterPilotDays: this.selected.calculated.days,
        charterPilotRate: this.selected.calculated.pilotRate,
        charterTotalVat: ((this.selected.cost || 0) * 0.2).toFixed(2),
        charterOverrideCurrency: this.$store.getters['global/currency'].toUpperCase(),
        charterReferralSource: this.$store.state.global.entry_url || this.brandSetting('domain'),
      },
      CharterDetails: {
        charterDate: moment(this.searchData.dates).format('YYYY-MM-DD'),
        return: this.searchData.routeType === 'return',
        numberOfDays: this.selected.calculated.days,
        pvpItemId: this.selected.base.closest?.PvpItemId,
      },
      CharterRouteLegs: this.getCharterRouteLegs(this.searchData.destinations),
    }

    data.CharterQuote.charterFlyingHours = _.sumBy(data.CharterRouteLegs, 'ete')

    this.updateSentryContext('capture-data', data)

    if (this.isProduction) {
      await this.$recaptchaLoaded()
      data.RecaptchaToken = await this.$recaptcha('social')

      await this.captureAxios.post('/CharterEnquiryData/CharterEnquiryImportNew', data, {
        auth: {
          username: 'importCharter',
          password: 'LetsHaveItThen',
        },
      })
    } else {
      // tslint:disable-next-line:no-console
      console.debug('Would have posted data', data)
    }

    this.navigateTo('success')
  }

  private getCharterRouteLegs(legs: BaseInterface[]): CharterRouteLeg[] {
    let retlegs

    // legs set the ete & distance to get to that point

    switch (this.$store.getters['search/routeType']) {
      case 'tour':
        retlegs = this.getRouteLegsTour(legs)
        break
      case 'one-way':
        retlegs = this.getRouteLegsOneWay(legs)
        break
      case 'return':
      default:
        retlegs = this.getRouteLegsReturn(legs)
        break
    }

    retlegs = this.addDepartureTimes(retlegs)

    retlegs = this.addBaseLegs(retlegs, legs)

    return retlegs.map((leg, index) => {
      leg.legNumber = index
      return leg
    })
  }

  private addDepartureTimes(charterLegs: CharterRouteLeg[]): CharterRouteLeg[] {
    if (this.searchData.departure && charterLegs[0]) {
      charterLegs[0].departing = this.searchData.departure.case === 'depart_at'
        ? this.searchData.departure.time as string
        : moment(this.searchData.departure.time as string, 'HH:mm').subtract(charterLegs[0].ete as number, 'hours').format('HH:mm')
    }

    if (this.searchData.arrival && charterLegs[1]) {
      charterLegs[1].departing = this.searchData.arrival.case === 'depart_at'
        ? this.searchData.arrival.time as string
        : moment(this.searchData.arrival.time as string, 'HH:mm').subtract(charterLegs[1].ete as number, 'hours').format('HH:mm')
    }

    return charterLegs
  }

  private addBaseLegs(charterLegs: CharterRouteLeg[], enquiryLegs: BaseInterface[]): CharterRouteLeg[] {
    const distCalc = new DistanceCalculator
    const timeCalc = new TimeCalculator
    const base = this.selected.base.closest as BaseAPI
    const ret = []

    // start is not the base, need a start->base leg
    if (enquiryLegs[0].venueId !== base.VenueId) {
      const baseDistance = distCalc.distanceBetween({lng: base.CMPLongitude, lat: base.CMPLatitude}, enquiryLegs[0])
      ret.push({
        legNumber: 0,
        origin: {
          locationName: base.VenueName,
          venueId: base.VenueId,
          postcode: base.Postcode,
          latitude: base.CMPLatitude,
          longitude: base.CMPLongitude,
        },
        destination: {
          locationName: enquiryLegs[0].venueName,
          venueId: enquiryLegs[0].venueId,
          postcode: enquiryLegs[0].postcode,
          charterHelipadId: enquiryLegs[0].helipadId,
          latitude: enquiryLegs[0].lat,
          longitude: enquiryLegs[0].lng,
        },
        distance: baseDistance,
        ete: timeCalc.calculate(baseDistance, this.selected.aircraft.cruiseRate),
        emptyLeg: true,
        description: 'position to start',
      } as CharterRouteLeg)
    }

    // add charter legs
    ret.push(...charterLegs)

    // finally need to get back to the base, if we're not there already
    const lastLeg = charterLegs[charterLegs.length - 1]
    if (lastLeg.destination.venueId !== base.VenueId) {
      const baseDistance = distCalc.distanceBetween({lng: base.CMPLongitude, lat: base.CMPLatitude}, {lng: lastLeg.destination.longitude as number, lat: lastLeg.destination.latitude as number})
      ret.push({
        legNumber: 0,
        origin: lastLeg.destination,
        destination: ret[0].origin,
        distance: baseDistance,
        ete: timeCalc.calculate(baseDistance, this.selected.aircraft.cruiseRate),
        emptyLeg: true,
        description: 'back to base',
      })
    }

    return ret
  }

  private getRouteLegsTour(legs: BaseInterface[]): CharterRouteLeg[] {
    const legLocation = {
      locationName: legs[0].venueName,
      venueId: legs[0].venueId,
      charterHelipadId: legs[0].helipadId,
      postcode: legs[0].postcode,
      latitude: legs[0].lat,
      longitude: legs[0].lng,
    } as CharterLegLocation

    return [{
      legNumber: 1,
      distance: null,
      ete: this.$store.getters['search/duration'] / 60,
      emptyLeg: false,
      description: `tour leg around ${legs[0].venueName}`,
      origin: legLocation,
      destination: legLocation,
    } as CharterRouteLeg]
  }

  private getRouteLegsOneWay(legs: BaseInterface[]): CharterRouteLeg[] {
    return this.getRouteLegs(legs)
  }

  private getRouteLegsReturn(legs: BaseInterface[]): CharterRouteLeg[] {
    return this.getRouteLegs(legs).concat(this.getRouteLegs([...legs].reverse()))
  }

  private getRouteLegs(legs: BaseInterface[]): CharterRouteLeg[] {
    const timeCalc = new TimeCalculator
    const distCalc = new DistanceCalculator
    const ret: any[] = []

    const length = legs.length
    for (let index = 1; index < length; ++index) {
      const leg = legs[index]
      const prevLeg = legs[index - 1]
      const distance = distCalc.distanceBetween({lat: leg.lat, lng: leg.lng}, {lat: legs[index - 1].lat, lng: legs[index - 1].lng})

      ret.push({
        legNumber: 0,
        distance,
        ete: distance > 0 ? timeCalc.calculate(distance, this.selected.aircraft.cruiseRate) : null,
        emptyLeg: false,
        description: `${prevLeg.venueName} to ${leg.venueName}`,
        origin: {
          locationName: prevLeg.venueName,
          venueId: prevLeg.venueId,
          charterHelipadId: prevLeg.helipadId,
          postcode: prevLeg.postcode,
          latitude: prevLeg.lat,
          longitude: prevLeg.lng,
        },
        destination: {
          locationName: leg.venueName,
          venueId: leg.venueId,
          charterHelipadId: leg.helipadId,
          postcode: leg.postcode,
          latitude: leg.lat,
          longitude: leg.lng,
        },
      } as CharterRouteLeg)
    }

    return ret
  }
}
