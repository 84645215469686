import AircraftInterface from '@/models/aircraft'
import BaseInterface from '@/models/base'
import LegInterface from '@/models/leg'
import PackageInterface from '@/models/package'
import SearchData from '@/models/search-data'

import * as turf from '@turf/turf'
import * as _ from 'lodash'

export default class CostCalculator {
  distance: number = 0
  baseDistance: number = 0
  days: number = 1
  legs: LegInterface[]
  pkg: PackageInterface
  aircraft: AircraftInterface
  searchData: SearchData
  destinations: BaseInterface[]
  base: any

  type: string = 'distance'

  constructor(result: any) {
    this.pkg = result.package
    this.aircraft = result.aircraft
    this.base = result.base
  }

  setType(type: string): this {
    this.type = type

    return this
  }

  setSearchData(searchData: SearchData): this {
    this.searchData = searchData
    this.destinations = searchData.destinations

    return this
  }

  setRoute(legs: LegInterface[]): this {
    this.legs = legs

    return this
  }

  addBase(base: any): void {
    let distance = 0
    const basePoint = turf.point([base.closest.CMPLongitude, base.closest.CMPLatitude])
    const startPoint = turf.point([this.searchData.destinations[0].lng, this.searchData.destinations[0].lat])
    const endPoint = turf.point([this.searchData.destinations[this.searchData.destinations.length - 1].lng, this.searchData.destinations[this.searchData.destinations.length - 1].lat])

    switch (this.searchData.routeType) {
      case 'return':
      case 'tour':
        // to start * 2
        distance += 2 * turf.distance(basePoint, startPoint, {units: 'nauticalmiles'})
        break
      case 'one-way':
        // to start
        // from end
        distance += turf.distance(basePoint, startPoint, {units: 'nauticalmiles'}) + turf.distance(endPoint, basePoint, {units: 'nauticalmiles'})
        break
    }

    this.baseDistance = parseFloat(distance.toFixed(0))
  }

  calculate(): number {
    let cost = 0
    const hours = this.calculateTime()

    cost += this.base.closest.MachineCost * hours
    cost += this.base.closest.PilotCost * this.days
    if (this.days > 1) {
      cost += (this.days - 1) * 150
    }

    return cost
  }

  calculateTime(): number {
    return _.sumBy(this.legs, 'ete')
  }
}
