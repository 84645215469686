










import {Currencies} from '@/store/global'

import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import {mapState} from 'vuex'

@Component({
  computed: {
    ...mapState('global', ['currency']),
  },
})
export default class CurrencySwitcher extends Vue {
  currencies = Object.values(Currencies)

  setCurrency(curr: any) {
    this.$store.dispatch('global/setCurrency', curr.iso)
  }
}
