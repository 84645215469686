import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'

// Some defaults
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || 'https://api.helicoptercharter.app/'
axios.defaults.headers.common['Content-Type'] = 'application/json'

Vue.use(VueAxios, axios)

export default axios
