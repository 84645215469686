



















import Utilities from '@/mixins/utilities'
import UTMObject from '@/models/utm'
import BrandProvider from '@/utils/brand-provider'

import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'

@Component
export default class Home extends mixins(Utilities) {
  mounted() {
    if (this.$route.query.brand) {
      BrandProvider.set(this.$route.query.brand as string)
    }

    this.$store.dispatch('global/setEntryUrl', document.location.href)

    if (this.$route.query.utm_source) {
      this.$store.dispatch('global/setUTM', {
        source: this.$route.query.utm_source,
        medium: this.$route.query.utm_medium,
        campaign: this.$route.query.utm_campaign,
        term: this.$route.query.utm_term,
        content: this.$route.query.utm_content,
      } as UTMObject)
    }

    this.$router.push({name: 'search-form', params: {brand: BrandProvider.get()}})
  }
}
