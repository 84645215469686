import {library} from '@fortawesome/fontawesome-svg-core'

import {faInstagram} from '@fortawesome/free-brands-svg-icons'

import {faHelicopter as fadHelicopter, faUser} from '@fortawesome/pro-duotone-svg-icons'
import {faCheck, faCircleNotch, faExclamationTriangle, faExternalLink, faMapMarkerExclamation, faPhone, faRedo} from '@fortawesome/pro-light-svg-icons'
import {faHelicopter, faMapMarker} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faCheck,
  faCircleNotch,
  faExclamationTriangle,
  faExternalLink,
  faHelicopter,
  faInstagram,
  fadHelicopter,
  faMapMarker,
  faMapMarkerExclamation,
  faPhone,
  faRedo,
  faUser
)
