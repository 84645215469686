import BaseInterface from '@/models/base'
import * as turf from '@turf/turf'
import {LatLngLiteral} from 'leaflet'

export default class DistanceCalculator {
  destinations: BaseInterface[]
  distances: number[]

  setDestinations(destinations: BaseInterface[]): void {
    this.destinations = []
    destinations.forEach(destination => this.addDestination(destination))
  }

  addDestination(destination: BaseInterface): void {
    this.destinations.push(destination)
  }

  dropDestination(destination: BaseInterface): void {
    this.dropDestinationIndex(this.destinations.findIndex(destinationsDestination => destination === destinationsDestination))
  }

  dropDestinationIndex(index: number): void {
    this.destinations.splice(index, 1)
  }

  distanceBetween(destination1: BaseInterface | LatLngLiteral, destination2: BaseInterface | LatLngLiteral): number {
    const from = turf.point([destination1.lng, destination1.lat])
    const to = turf.point([destination2.lng, destination2.lat])

    return parseFloat(turf.distance(from, to, {units: 'nauticalmiles'}).toFixed(2))
  }

  calculate(): number {
    let distance = 0
    const distances: number[] = []

    const length = this.destinations.length - 1

    if (length < 1) {
      return 0
    }

    for (let index = 0; index < length; ++index) {
      distance += distances[this.destinations[index].destinationIndex as number] = this.distanceBetween(this.destinations[index], this.destinations[index + 1])
    }

    this.distances = distances

    return distance
  }
}
