









import Utilities from '@/mixins/utilities'

import {mixins} from 'vue-class-component'
import {Component} from 'vue-property-decorator'

@Component
export default class UhOh extends mixins(Utilities) {
}
