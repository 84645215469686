import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './app.vue'
import axios from './plugins/axios'
import router from './router'
import store from './store'

import moment from '@/plugins/moment'
import VueMoment from 'vue-moment'

import VCalendar from 'v-calendar'
import VDatePicker from 'v-calendar'

import '@/stylesheets/_tailwind.scss'
import '@/stylesheets/vue-multiselect.scss'
import '@/stylesheets/vue-select.scss'
import VueMultiselect from 'vue-multiselect'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import vSelect from 'vue-select'

import 'leaflet/dist/leaflet.css'
import {LMap, LMarker, LPopup, LTileLayer, LTooltip} from 'vue2-leaflet'

import '@/plugins/fontawesome'
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'

import BrandProvider from '@/utils/brand-provider'
import {dom} from '@fortawesome/fontawesome-svg-core'
import L from 'leaflet'

dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('vue-multiselect', VueMultiselect)
Vue.component('v-select', vSelect)
Vue.use(VCalendar)
Vue.use(VDatePicker)
Vue.use(VueReCaptcha, {siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY})

Vue.use(VueMoment, {moment})

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('l-popup', LPopup)
Vue.component('l-tooltip', LTooltip)

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

Vue.config.productionTip = false

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://1b5d7abc3d1f4e90a65581531667207f@sentry.io/1876743',
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
    release: `charter-quotation@${process.env.VUE_APP_VERSION}`,
    autoSessionTracking: false,
  })
  Sentry.setTag('brand', BrandProvider.get())
}

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    render: (h: any) => h(App),
    created() {
      // Logout on 401 response
      // axios.interceptors.response.use(
      //   response => response,
      //   error => {
      //     if (error.response.status === 401 && error.config.url.indexOf('users/sign_in') === -1) {
      //       this.$router.push('/')
      //       this.$store.dispatch('auth/logout')
      //     }
      //     return Promise.reject(error)
      //   }
      // )
    },
    router,
    store,
    axios,
  } as any).$mount()

  document.body.appendChild(app.$el)
})
