import * as _ from 'lodash'
import {Module} from 'vuex'

import axios from '@/plugins/axios'
import moment from '@/plugins/moment'
import BrowserStorage from '@/utils/browser-storage'

let localStoragePackages = BrowserStorage.restore('packages')
if (!localStoragePackages.updated_at || moment().diff(localStoragePackages.updated_at, 'hours') > 24) {
  localStoragePackages = {}
}

const initialState: any = {
  packages: localStoragePackages.packages || [],
}

const saveToLocalStorage = (state: any) => {
  state.updated_at = moment()
  BrowserStorage.save('packages', state)
}

export const packages: Module<any, any> = {
  namespaced: true,
  state: initialState,
  actions: {
    async fetchPackages({commit, state}) {
      if (_.isEmpty(initialState.packages)) {
        const packageList: any = await axios.get('/packages')
        let {data: packageData} = packageList
        packageData = packageData.filter((pkg: any) => ![1618].includes(pkg.PackageId))

        commit('SET_PACKAGES', packageData)

        return packageData
      }

      return state.packages.filter((pkg: any) => ![1618].includes(pkg.PackageId))
    },
  },
  mutations: {
    SET_PACKAGES(state, packageList: any) {
      state.packages = packageList
      saveToLocalStorage(state)
    },
  },
}
